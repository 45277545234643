import React from 'react';
import { Link } from 'react-router-dom';
import '../App.css';

function LandingPage() {
  return (
    <div className="App">
      <header className="App-header">
        <h1>Coming Soon</h1>
        <p>Our website is under construction. Check back soon!</p>
        <Link className="App-link" to="/contact">
          Sign up to receive a notification
        </Link>
      </header>
    </div>
  );
}

export default LandingPage;
