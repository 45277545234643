import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import '../App.css';

function ContactPage() {
  const [email, setEmail] = useState('');
  const [status, setStatus] = useState(null); // null, 'success', 'error'

  const handleSubmit = async (e) => {
    e.preventDefault();
    setStatus(null);

    try {
      const response = await fetch(`${process.env.REACT_APP_API_URL}/api/subscribe`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({ email })
      });

      if (response.ok) {
        setStatus('success');
        setEmail('');
      } else {
        const data = await response.json();
        setStatus(data.error || 'An error occurred.');
      }
    } catch (error) {
      console.error('Error submitting form:', error);
      setStatus('An error occurred. Please try again.');
    }
  };

  return (
    <div className="App">
      <header className="App-header">
        <h1>Subscribe to Our Newsletter</h1>
        <form onSubmit={handleSubmit}>
          <input
            type="email"
            name="email"
            placeholder="Enter your email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            required
          />
          <button type="submit">Subscribe</button>
        </form>
        {status === 'success' && <p className="success-message">Thank you for subscribing!</p>}
        {status && status !== 'success' && <p className="error-message">{status}</p>}
        <Link className="App-link" to="/">
          Back to Home
        </Link>
      </header>
    </div>
  );
}

export default ContactPage;
